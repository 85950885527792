import React from 'react';

export enum Kind {
  LargestHeader = 'LargestHeader',
  SidebarLabel = 'SidebarLabel',
  Quote = 'Quote',
  EntryTitle = 'EntryTitle',
}

interface Props {
  kind: Kind;
  children: any;
  style?: CSS.Properties;
}

const Header = ({ kind, children, style }: Props) => {
  switch (kind) {
    case Kind.LargestHeader:
      return (
        <h1 className="LargestHeader" style={style}>
          {children}
        </h1>
      );
    case Kind.SidebarLabel:
      return (
        <h2 className="SidebarLabel" style={style}>
          {children}
        </h2>
      );
    case Kind.EntryTitle:
      return (
        <h3 className="EntryTitle" style={style}>
          {children}
        </h3>
      );
    case Kind.Quote:
      return (
        <blockquote className="Quote" style={style}>
          {children}
        </blockquote>
      );
    default:
      return (
        <p>
          <em>
            UNKNOWN Type:
            {kind}
          </em>
          {children}
        </p>
      );
  }
};
Header.defaultProps = {
  style: {},
};

export default Header;
