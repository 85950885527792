/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import '../styles/globals.scss';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import SEO from './seo';

const Layout = ({ children, className }: any) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={`container ${className || ''}`}>
      <SEO title={data.site.siteMetadata.title} />
      <Helmet
        link={[
          { href: 'https://use.typekit.net/lnh7swg.css', rel: 'stylesheet' },
          {
            href: 'https://anchor.fm/s/555528f0/podcast/rss',
            rel: 'alternate',
            type: 'application/rss+xml',
            title: 'Subscribe to the GaysMaking Podcast',
          },
        ]}
      />
      <Sidebar />
      <section>{children}</section>
    </div>
  );
};

export default Layout;
