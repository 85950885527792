import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './Logo.scss';

export enum LogoVariant {
  Light,
  Dark,
}

interface Props {
  variant?: LogoVariant;
  className?: string;
}

const LightLogo = () => (
  <StaticImage
    src="../images/assets/ImprovedLogo.svg"
    alt="Gays Making"
    placeholder="tracedSVG"
    layout="fullWidth"
  />
);

const DarkLogo = () => (
  <StaticImage
    src="../images/assets/ImprovedLogo-dark.svg"
    alt="Gays Making"
    placeholder="tracedSVG"
    layout="fullWidth"
  />
);

const Logo = ({ variant, className }: Props) => (
  <span className={className}>{variant === LogoVariant.Dark ? <DarkLogo /> : <LightLogo />}</span>
);

Logo.defaultProps = {
  variant: LogoVariant.Light,
  className: 'logo-std',
};

export default Logo;
