import React from 'react';
import './Sidebar.scss';
import { Link, StaticQuery, graphql } from 'gatsby';
import Typography, { Kind } from './Typography';
import Logo from './Logo';

const Entry = ({ children, to, color }: { children: any; color: string; to: any }) => (
  <li className={color}>
    <Typography kind={Kind.SidebarLabel}>
      <Link to={to} data-todo="Dynamic" activeClassName="active">
        {children}
      </Link>
    </Typography>
  </li>
);

const Sidebar = () => (
  <StaticQuery
    query={graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { glob: "**/episodes/**" }, isFuture: {eq: false} }
          limit: 1
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              slug
              fields {
                slug
              }
              frontmatter {
                date(fromNow: true)
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div id="sidebar">
        <Link to="/">
          <Logo />
        </Link>
        <ul id="nav">
          <Entry to={`${data.allMdx.edges[0]?.node.fields.slug || '/'}`} color="red">
            Current Episode
          </Entry>
          <Entry to="/archive" color="orange">
            All Episodes
          </Entry>
          {/* <Entry to="/creators" color="yellow">
            Affilated Creators
          </Entry> */}
          <Entry to="/sponsors" color="green">
            Sponsors
          </Entry>
          <Entry to="/grayson" color="blue">
            Host
          </Entry>
          <Entry to="/about" color="purple">
            About Show
          </Entry>
        </ul>
      </div>
    )}
  />
);

export default Sidebar;
